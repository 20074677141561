import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import apiDataReducer from "./apiDataSlice"
import globalPromptsReducer from "./globalPromptsSlice"
import promptsReducer from "./promptsSlice"
export const store = configureStore({
    reducer: {
        auth: authReducer,
        data: apiDataReducer,
        globalPrompts: globalPromptsReducer,
        prompts: promptsReducer,
    },
    devTools: process.env.NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;