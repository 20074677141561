import promptsApi from "common/api/PromptsApi";
import InfiniteScroll from "components/InfiniteScroll";
import { ErrorToast, SuccessToast } from "components/toasts";
import { Dropdown, Rating } from "flowbite-react";
import { useEffect, useState } from "react";
import { createPromptsModule } from "./modules";
import { useSelector } from "react-redux";
import SearchInput from "components/SearchInput";
import MoreMenuIcon from "components/icons/MoreMenuIcon";
import DeleteIcon from "components/icons/DeleteIcon";
import HighlightText from "components/HighlightText";
import { humanizeDuration } from "common/time";
import BarChart from "components/charts/BarChart";
import DataTable from "components/DataTable";
const PromptRating: React.FC<any> = ({ prompt, onRating }) => {
    const [rating, setRating] = useState<number>(0)
    const [error, setError] = useState<string>()
    useEffect(() => {
        setRating(prompt.rating || 0)
    }, [prompt])

    const roundedRating = Math.round(rating)
    const onRatingClick = async (prompt: any, userRating: number) => {
        setRating(userRating)
        try {
            onRating && await onRating(prompt, userRating)
        } catch (err) {
            console.log(err);
            setError('Could not update user rating. Please contact admin if the problem persists.')
        }
    }
    return <div>
        <Rating>
            <Rating.Star filled={roundedRating > 0} onClick={() => { onRatingClick(prompt, 1) }} />
            <Rating.Star filled={roundedRating > 1} onClick={() => { onRatingClick(prompt, 2) }} />
            <Rating.Star filled={roundedRating > 2} onClick={() => { onRatingClick(prompt, 3) }} />
            <Rating.Star filled={roundedRating > 3} onClick={() => { onRatingClick(prompt, 4) }} />
            <Rating.Star filled={roundedRating > 4} onClick={() => { onRatingClick(prompt, 5) }} />
        </Rating>
        {error && <ErrorToast message={error} onClose={() => { setError('') }} />}
    </div>
}

const PromptActions: React.FC<any> = ({ prompt, handleDelete }) => {
    const [error, setError] = useState<string>()
    const [message, setMessage] = useState<string>()
    useEffect(() => {
        setError('')
        setMessage('')
    }, [])
    const handleDeleteprompt = async (prompt: any) => {
        try {
            await handleDelete(prompt)
            setMessage('Successfully deleted prompt.')
        } catch (err) {
            console.log('err')
            setError('Could not delete prompt. Please contact admin if the problem persists.')
            setMessage('')
        }

    }
    return <div>
        <Dropdown label={<div className="ps-2 pe-2"><MoreMenuIcon /></div>} inline theme={{ arrowIcon: 'hidden' }}>
            <Dropdown.Item><div className="flex gap-2 items-center justify-center text-md text-[#FC4D66]"
                onClick={() => handleDeleteprompt(prompt)}>
                <div className="text-red pt-1"><DeleteIcon /></div> Delete</div>
            </Dropdown.Item>

        </Dropdown>
        {error && <ErrorToast message={error} onClose={() => { setError('') }} />}
        {message && <SuccessToast message={message} onClose={() => { setMessage('') }} />}
    </div>
}
const PromptsHistory: React.FC<any> = ({ onRating }) => {
    const [searchTerm, setSearchTerm] = useState<string>()
    const promptsModule = createPromptsModule()
    const { result, isLoading } = useSelector((state: any) => state.data?.prompts || {})
    const [prompts, setPrompts] = useState<any[]>([])

    useEffect(() => {
        // console.log('new prompts result')
        // result && console.log(result.results)
        result && setPrompts(result.results)
    }, [result])

    const fetchData = async () => {
        const res = await promptsModule.fetchNextPage()
        if (res) {
            setPrompts((prevPrompts) => {
                const newPrompts = [...prevPrompts, ...res.data.results];
                return newPrompts;
            });
        }
    }
    const handleDelete = async (prompt: any) => {
        await promptsModule.deletePrompt(prompt)
        await promptsModule.promptsFetcher.fetch()
    }
    const handleSearch = async (searchTerm: string) => {
        console.log(`Search global prompts: ${searchTerm}`)
        promptsModule.updateSearchTerm(searchTerm)
        promptsModule.promptsFetcher.fetch()
        setSearchTerm(searchTerm)
    }

    return <div className="w-full ml-auto 
    rounded rounded-md ">
        <div className="w-full flex justify-between flex-wrap items-center p-2">
            <div className="w-full  md:max-w-[400px] text font-bold text-sm md:text-xl pt-1 pe-1 pb-1 uppercase">History</div>
            <div className="w-full md:max-w-[400px]">
                <SearchInput onSearch={handleSearch} />
            </div>
        </div>
        <InfiniteScroll className="w-full flex flex-col gap-4 max-h-[600px] 
    rounded rounded-md p-2
    overflow-auto bg-white h-full"
            fetchData={fetchData}
            paginationSelector={promptsModule.paginationSelector}
        >
            {prompts?.map((prompt: any, index: number) => {
                const answer = prompt.result?.text;
                const dataType = prompt.result?.data_type || 'text'
                // console.log(prompt)
                const durationFormatted = Math.round(prompt.duration_ms / 1000)
                return <div className="flex flex-col w-full gap-2 text-left" key={`prompt-${index}`}>
                    <div className="text text-sm w-full flex flex-col gap-2">
                        <p className="inline">
                            <strong>Prompt </strong>
                            (used <span className="text text-blue-500 font-bold">{prompt.total_tokens}</span> tokens,
                            took <span className="text text-green-500 font-bold">{durationFormatted} seconds</span>
                            ):
                        </p>
                        <HighlightText text={prompt.query} searchTerm={searchTerm} />
                    </div>
                    <div className="text text-sm w-full max-h-[200px] overflow-y-auto">
                        <span className="text font-bold pe-2">
                            Answer:
                        </span>
                        {dataType === 'bar_chart' ?
                            <BarChart data={prompt.result.data.data} categories={prompt.result.data.categories} />
                            :
                            dataType === 'table' ?
                                <DataTable data={prompt.result.data} />
                                :
                                <HighlightText text={answer} searchTerm={searchTerm} />
                        }

                    </div>
                    <div className='flex gap-2 items-center justify-between w-full me-4'>
                        <div className="flex gap-2 items-center">
                            <PromptRating prompt={prompt} onRating={onRating} />
                            <span className='font-bold text-sm'>({prompt?.rating?.toFixed(2)}/5)</span>
                        </div>
                        <PromptActions prompt={prompt} handleDelete={handleDelete} />
                    </div>

                    <hr className="w-full my-4 border-t border-[#E5E9EE]"></hr>

                </div>
            })}
        </InfiniteScroll>
    </div>
}

export default PromptsHistory;