import axios, { AxiosInstance, AxiosPromise, AxiosResponse } from 'axios'
import { createAxiosInstance, dataFetcher } from '.';
import CONFIG from 'config';
import { store } from 'store';
import { updateTotalCount } from 'store/globalPromptsSlice';

const encodeData = (data: string) => {
  return data && btoa(encodeURIComponent(data))
}
const PromptsApi = () => {
    const axiosInstance = createAxiosInstance()
    return {
        getGlobalPrompts: async (searchTerm: string = '', skip: number = 0, limit: number = CONFIG.GLOBAL_PROMPTS_LIMIT): AxiosPromise<any> => {
            const params = searchTerm ? { searchTerm, skip, limit } : { skip, limit }
            return axiosInstance.get(`${CONFIG.API_URL}/global-prompts`, {
                params
            })
        },
        getPrompts: async (searchTerm: string = '', skip: number = 0, limit: number = CONFIG.PROMPTS_LIMIT): AxiosPromise<any> => {
            const params = searchTerm ? { searchTerm, skip, limit } : { skip, limit }
            return axiosInstance.get(`${CONFIG.API_URL}/prompts`, { params })
        },
        getStats: async (): AxiosPromise<any> => {
            const params = {}
            return axiosInstance.get(`${CONFIG.API_URL}/stats`, { params })
        },
        getUsersStats: async (): AxiosPromise<any> => {
            const params = {}
            return axiosInstance.get(`${CONFIG.API_URL}/stats/users`, { params })
        },
        getTokensCount: async (data: string): AxiosPromise<any> => {
            const encodedData = encodeData(data)
            return axiosInstance.post(`${CONFIG.API_URL}/tokens-count`, { data: encodedData })
        },
        updatePrompt: async (prompt: any): AxiosPromise<any> => {
            return axiosInstance.patch(`${CONFIG.API_URL}/prompts/${prompt?.id}`, {
                rating: prompt.rating
            })
        },
        deletePrompt: async (prompt: any): AxiosPromise<any> => {
            return axiosInstance.delete(`${CONFIG.API_URL}/prompts/${prompt?.id}`)
        },
        runPrompt: async (prompt: string, contextData: string, username: string, contentType: string, modelName: string): AxiosPromise<any> => {
            const encodedContextData = encodeData(contextData)
            const encodedPrompt = encodeData(prompt)
            return axiosInstance.post(`${CONFIG.API_URL}/prompts/run`, {
                prompt: encodedPrompt,
                context_data: encodedContextData,
                content_type: contentType,
                username,
                model_name: modelName
            })
        },
        uploadContextFiles: async (formData: any) => {
            return axiosInstance.post('/context-data/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        }

    }
}

const promptsApi = PromptsApi()

export const fetchGlobalPrompts = async () => {
    const { searchTerm, skip, limit } = store.getState().globalPrompts
    const res = await promptsApi.getGlobalPrompts(searchTerm, skip, limit)
    store.dispatch(updateTotalCount(res.data.total_count))
    return res
}
export const globalPromptsFetcher = dataFetcher(fetchGlobalPrompts, 'globalPrompts')


export default promptsApi